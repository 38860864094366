import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import React from "react";

function Loading() {
  return (
    <div className="loading">
      <FontAwesomeIcon icon={faSpinnerThird} spin />
    </div>
  );
}

export default Loading;
