import React from "react";
import "./../App.scss";
import logo from './../assets/logo_ademicon.svg';

function Header() {
  return (
    <div className="logo">
      <div className="wrap">
        <img src={logo} alt="" />
      </div>
    </div>
  );
}

export default Header;
