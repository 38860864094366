import React, { useState, useEffect } from 'react';
import './../App.scss';
import Header from './../Components/logo';
import expired from './../assets/icon_smile.svg';
const axios = require('axios').default;
const queryString = require('query-string');

function AuthSucessScreen() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);

  return (
    <div className="App">
      <div>
        <Header />
        <div className="wrap page-wrap">
          <h1>Autorização concedida</h1>
          <p>
            Seu score de crédito esta sendo analisado, volte ao consultor
            Ademicon Crédito que está lhe atendendo para entender seu resultado!
          </p>
          <div className="big-image">
            <img src={expired} alt="Código expirado" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthSucessScreen;
