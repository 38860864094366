import React, { useState, useEffect } from 'react';
import './../App.scss';
import Countdown from 'react-countdown';
import Header from './../Components/logo';
import ReactCodeInput from 'react-code-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/pro-light-svg-icons';
import Loading from './../Components/loading';

const axios = require('axios').default;
const queryString = require('query-string');

let currentMileSeconds = 900000;

const pinCode = {
  inputStyle: {
    fontFamily: 'Open Sans',
    fontSize: '58px',
    MozAppearance: 'textfield',
    maxWidth: '70px',
    height: '90px',
    borderRadius: '3px',
    paddingLeft: '10px',
    marginLeft: '6px',
    backgroundColor: 'white',
    color: '#333333',
    border: '1px solid #BBBDBF',
  },
  inputStyleInvalid: {
    fontFamily: 'Open Sans',
    MozAppearance: 'textfield',
    maxWidth: '70px',
    height: '90px',
    borderRadius: '3px',
    paddingLeft: '6px',
    backgroundColor: 'white',
    color: '#333333',
    border: '1px solid #BBBDBF',
  },
};

function CodeScreen(props) {
  const [loading, setLoading] = useState(false);
  const [retryPinTentativesMax, setRetryPinTentativesMax] = useState(3);
  const [resendMax, setResendMax] = useState(3);

  useEffect(() => {
    props.setUserData({
      ...props.userData,
      code: '',
    });
  }, []);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      props.setFlow(3);
      return <></>;
    } else {
      currentMileSeconds = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
      return (
        <span>
          {minutes <= 9 ? '0' + minutes : minutes}:
          {seconds <= 9 ? '0' + seconds : seconds}
        </span>
      );
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    props.setUserData({
      [name]: value,
    });
  };

  const onChangePinCode = (e) => {
    props.setUserData({
      ...props.userData,
      code: e,
    });
  };

  const confirmCode = () => {
    setLoading(true);

    const parsed = queryString.parse(window.location.search);
    console.log(parsed.deal);

    let params = {
      clientIP: '192.168.0.23',
      userAgent:
        'Mozilla/5.0 (Linux; Android 8.0.0;) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.119 Mobile Safari/537.36',
      pin: props.userData.code,
    };

    axios
      .post(`${process.env.REACT_APP_API}he/pincheck/${parsed.deal}`, params)
      .then((response) => {
        props.setFlow(2);
        setLoading(false);
      })
      .catch((error) => {
        if (retryPinTentativesMax === 0) {
          props.setFlow(3);
          setLoading(false);
          return;
        }
        alert(
          'Código inválido você tem apenas ' +
            retryPinTentativesMax +
            ' tentativas'
        );
        setRetryPinTentativesMax(retryPinTentativesMax - 1);
        setLoading(false);
      });
  };

  const resendCode = () => {
    setLoading(true);
    setTimeout(() => {
      setResendMax(resendMax - 1);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <div className="wrap page-wrap">
            <h1>Código de autorização</h1>
            <div className="mb-xl">
              <p>
                Nós enviamos um SMS com um código temporário para o seu
                telefone. Por favor, verifique suas mensagens e digite o código
                para confirmar sua autorização.
              </p>
            </div>
            <div className="mb-xl">
              <ReactCodeInput
                onChange={onChangePinCode}
                name="code"
                type="number"
                fields={4}
                value={props.userData.code}
                {...pinCode}
                inputMode={'numeric'}
              />
            </div>
            <div>
              <button
                onClick={() => confirmCode()}
                disabled={!(props.userData.code || ''.length > 3)}
                className="button button-ademibank button-lg button-block"
              >
                {' '}
                Confirmar Código{' '}
              </button>
            </div>
            <div className="timer">
              <p className="text-light">Seu código irá expirar em:</p>
              <div className="counter">
                <div className="stopwatch">
                  <FontAwesomeIcon icon={faStopwatch} />
                </div>
                <Countdown
                  date={Date.now() + currentMileSeconds}
                  renderer={renderer}
                />
              </div>
              <div className="timer-send">
                <button
                  onClick={() => resendCode()}
                  disabled={!(resendMax > 0)}
                  className="button button-outline outlined-thin button-lg"
                >
                  Reenviar código{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CodeScreen;
