import React, { useState, useEffect } from 'react';
import './App.scss';
import Countdown from 'react-countdown';
import AuthScreen from './FlowsScreens/AuthScreen';
import CodeScreen from './FlowsScreens/CodeScreen';
import AuthSucessScreen from './FlowsScreens/AuthSucessScreen';
import AuthExpiredScreen from './FlowsScreens/AuthExpiredScreen';
const axios = require('axios').default;
const queryString = require('query-string');

function App() {
  const [flow, setFlow] = useState(0);
  const [userData, setUserData] = useState({
    nome: '',
    cpf: '',
    celular: '',
    offer: '',
    termId: '',
    termLink: '',
    isAccepted: false,
    code: '',
  });
  const [loading, setLoading] = useState(true);

  return (
    <div className="App">
      {flow === 0 && (
        <AuthScreen
          userData={userData}
          setUserData={setUserData}
          setFlow={setFlow}
        />
      )}
      {flow === 1 && (
        <CodeScreen
          userData={userData}
          setUserData={setUserData}
          setFlow={setFlow}
        />
      )}
      {flow === 2 && <AuthSucessScreen />}
      {flow === 3 && <AuthExpiredScreen />}
    </div>
  );
}

export default App;
