import React, { useState, useEffect } from 'react';
import './../App.scss';
import Countdown from 'react-countdown';
import Header from './../Components/logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { faIdCard } from '@fortawesome/pro-light-svg-icons';
import { faMobile } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import Loading from './../Components/loading';

const axios = require('axios').default;
const queryString = require('query-string');

function AuthScreen(props) {
  const [loading, setLoading] = useState(true);
  const [currentDeal, setCurrentDeal] = useState(0);
  const [termLink, setTermLink] = useState('');

  useEffect(() => {
    getOfferDetails();
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    props.setUserData({
      [name]: value,
    });
  };

  const getOfferDetails = () => {
    const parsed = queryString.parse(window.location.search);
    console.log(parsed.deal);
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}deals/terms/${parsed.deal}`)
      .then((response) => {
        // handle success
        console.log(response.data);
        props.setUserData({
          nome: response.data.nome,
          cpf: response.data.cpf,
          celular: response.data.celular,
          deal: response.data.deal,
          termLink: response.data.term_url,
          termId: response.data.term_id,
        });
        setTermLink(response.data.term_url);
        setCurrentDeal(response.data.deal);
      })
      .catch((error) => {
        console.log('erro ' + error);
        props.setFlow(3);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const confirmTerm = () => {
    setLoading(true);

    let params = {
      clientIP: '192.168.0.23',
      userAgent:
        'Mozilla/5.0 (Linux; Android 8.0.0;) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.119 Mobile Safari/537.36',
    };

    axios
      .post(`${process.env.REACT_APP_API}he/sendpin/${currentDeal}`, params)
      .then((response) => {
        props.setFlow(1);
        setLoading(false);
      })
      .catch((error) => {})
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <div className="wrap page-wrap">
            <h1>Autorização de análise</h1>
            <p>
              Valide e confirme as informações que você forneceu ao nosso
              parceiro para autorizar a análise no Sistema de Crédito do Banco
              Central(SCR). <br /> Para a confirmação enviaremos um código via
              SMS
            </p>
            <div className="mt-xl">
              <div className="input-floating-label">
                <div className="input-prefix-icon">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <input
                  type="text"
                  className={`input input-full ${
                    props.userData.nome != '' ? 'floating' : ''
                  }`}
                  name="nome"
                  type="text"
                  value={props.userData.nome}
                  onChange={handleInputChange}
                  disabled
                />
                <div className="input-label">Nome</div>
              </div>
            </div>
            <div className="mt-lg">
              <div className="input-floating-label">
                <div className="input-prefix-icon">
                  <FontAwesomeIcon icon={faIdCard} />
                </div>
                <input
                  className={`input input-full ${
                    props.userData.cpf != '' ? 'floating' : ''
                  }`}
                  name="cpf"
                  type="text"
                  value={props.userData.cpf}
                  onChange={handleInputChange}
                  disabled
                />
                <div className="input-label">CPF</div>
              </div>
            </div>
            <div className="mt-lg">
              <div className="input-floating-label">
                <div className="input-prefix-icon">
                  <FontAwesomeIcon icon={faMobile} />
                </div>
                <input
                  type="text"
                  className={`input input-full ${
                    props.userData.celular != '' ? 'floating' : ''
                  }`}
                  name="celular"
                  value={props.userData.celular}
                  onChange={handleInputChange}
                  disabled
                />
                <div className="input-label">Celular</div>
              </div>
            </div>
            <div className="mt-xl">
              <div className="flex checkbox-wrap">
                <div className="checkbox">
                  <label forHtml="isAccepted" className="label">
                    Li e estou de acordo com o{' '}
                    <a href={termLink} target="_blank" className="link">
                      Termo de aceite de consulta ao SCR
                    </a>{' '}
                    e ao Cadastro Positivo.
                  </label>
                  <div className="checkbox-input">
                    <input
                      name="isAccepted"
                      type="checkbox"
                      checked={props.userData.isAccepted}
                      onChange={handleInputChange}
                    />
                    <span
                      onClick={() => {
                        props.setUserData({
                          isAccepted: !props.userData.isAccepted,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} color="white" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-xl">
              <button
                onClick={() => confirmTerm()}
                className="button button-ademibank button-block button-lg"
                disabled={!props.userData.isAccepted}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AuthScreen;
